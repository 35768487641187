<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/register.jpg" alt="register" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title">
                  <h4 class="mb-4">创建新账号</h4>
                  <p>填写以下内容以创建新账号。</p>
                </div>
                <div class="clearfix">
                  <vs-input
                    v-validate="'required|digits:11'"
                    data-vv-validate-on="blur"
                    label-placeholder="电话号码"
                    name="电话号码"
                    placeholder="电话号码"
                    v-model="mobile"
                    class="w-full"
                  />
                  <span class="text-danger text-sm">{{ errors.first('mobile') }}</span>

                  <vs-input
                    :disabled="!verification_send"
                    ref="verification"
                    type="text"
                    name="短信验证码"
                    label-placeholder="短信验证码"
                    placeholder="短信验证码"
                    v-model="verification_code"
                    class="w-full mt-6"
                  />

                  <vs-input
                    v-if="verification_send"
                    ref="password"
                    type="password"
                    data-vv-validate-on="blur"
                    v-validate="'required|min:6|max:10'"
                    name="密码"
                    label-placeholder="密码"
                    placeholder="密码"
                    v-model="password"
                    class="w-full mt-6"
                  />
                  <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                  <vs-input
                    v-if="verification_send"
                    type="password"
                    v-validate="'min:6|max:10|confirmed:password'"
                    data-vv-validate-on="blur"
                    data-vv-as="密码"
                    name="confirm_password"
                    label-placeholder="确认秘密"
                    placeholder="确认秘密"
                    v-model="confirm_password"
                    class="w-full mt-6"
                  />
                  <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>

                  <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6">我接受所有条款和条件。</vs-checkbox>
                  <vs-button type="border" to="/login" class="mt-6">登录</vs-button>
                  <vs-button
                    v-if="!verification_send"
                    class="float-right mt-6"
                    @click="getSMSCode"
                    :disabled="!validateMobile"
                  >获取验证码</vs-button>
                  <vs-button
                    v-if="verification_send"
                    class="float-right mt-6"
                    @click="registerUser"
                    :disabled="!validateForm"
                  >注册</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: "",
      verification_code: "",
      verification_send:false,
      password: "",
      confirm_password: "",
      isTermsConditionAccepted: true
    };
  },
  computed: {
    validateMobile() {
      return !this.errors.any() && this.mobile != "";
    },

    validateForm() {
      return (
        !this.errors.any() &&
        this.mobile != "" &&
        this.verification_code != "" &&
        this.password != "" &&
        this.confirm_password != "" &&
        this.isTermsConditionAccepted === true
      );
    }
  },
  methods: {
    getSMSCode(){
      // console.log('ok');
      if (!this.validateMobile) return false;
      const payload = {
        vm: this,
        mobile: this.mobile
      };

      this.$store.dispatch("getSMSCode", payload);
      this.verification_send = true;
    },

    registerUser() {
      if (!this.validateForm) return false;
      const payload = {
        userDetails: {
          mobile: this.mobile,
          verified_code: this.verification_code,
          password: this.password
        },
        vm: this,
        callback: this.gotoLogin
      };
      this.$store.dispatch("registerTrainer", payload);
    },

    gotoLogin(){
      this.$router.push({path: '/login'});
    }
  }
};
</script>
